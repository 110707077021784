import React from 'react';
import './FeaturePost.css'; // Import the CSS file for styling

const FeaturePost = () => {
  return (
    <section className="feature-post-section">
      <div className="feature-post-container">
        <article className="partnership-announcement">
          <p>
            We're thrilled to work alongside partners like <a href="https://www.likehearted.io/" className="partner-link" target="_blank" rel="noopener noreferrer">LikeHearted</a> to pioneer the future of mental health with AI and digital health. Keen to collaborate? We're actively seeking more partners to join us in this endeavor.
          </p>
        </article>
        <article className="partnership-announcement">
          <p>
            We make our mission to improve mental health and incorporate technology into that mission. We are researching and developing <strong> <a href="https://www.thinchealth.org/the-heart-ai" className="partner-link" target="_blank" rel="noopener noreferrer"> The Heart AI </a></strong> with our partners.
          </p>
        </article>
      </div>
    </section>
  );
};

export default FeaturePost;
