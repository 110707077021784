import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/Header.js'
import Search from './components/Search.js';
import Hero from './components/Hero';
import Success from "./components/Success";
import FeaturePost from "./components/FeaturedPost.js"

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<> <Header />
            <Search />
            <main>
              <article>

                {/* HERO */}
                <Hero />

                {/* FEATURED POST */}
                <FeaturePost />

                {/* RECENT POST */}

                {/* RECOMMENDED POST */}

                {/* NEWSLETTER */}

                {/* FOOTER */}

              </article>
            </main> </>} 
          />

          <Route path="/success" element={<Success />} />

        </Routes>
      </div>

    </BrowserRouter>
  );
}

export default App;
