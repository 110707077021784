import React from 'react';
import { useState } from 'react';
import Logo from "../assets/images/thincmind.png";
import Search from './Search'; // import the Search component

const Header = () => { 
    const [isNavbarActive, setIsNavbarActive] = useState(false);
    const [isSearchBarActive, setIsSearchBarActive] = useState(false); // new state for the search bar

    const toggleNavbar = () => {
        setIsNavbarActive(!isNavbarActive);
    };

    return (
        <header className="header section" data-header>
            <div className="container">
                <a href="/" className="logo">
                    <img src={Logo} width="300" height="300" alt=" ThincMind Logo" />
                </a>
            </div>
        </header>
    );
}

export default Header;
