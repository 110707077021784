import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';

// import your CSS file here if you're not using CSS-in-JS

const Hero = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const navigate = useNavigate();
  const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleClick = (e) => {
    e.preventDefault();
    if (!email || !validEmail.test(email)) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
      // navigate("/success", { state: { email } });
      sendEmail();
    }
  };
  const sendEmail = () => {
    emailjs
      .send(
        'service_uvydxa7',
        'tonynguyen0403',
        { email },
        'XjFR2Io7qSjeMQ9Be'
      )
      .then(
        () => {
          navigate("/success", { state: { email } });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="section hero" aria-label="home">
      <div className="container">
        <h1 className="h1 hero-title">
          <strong className="strong">Hey, we're <strong style={{color: 'rgb(60,179,113)'}}>  ThincMind. </strong>  </strong> Explore our insights, narratives, and innovations in <strong style={{color: 'rgb(0,191,255)', textDecoration: 'underline'}}>mental health</strong>
        </h1>

        <div className="wrapper">
          <form onSubmit={handleClick} className="newsletter-form">
            <input 
              type="email" 
              name="email_address" 
              placeholder="Your email address" 
              className={`email-field ${errorMsg ? 'error' : ''}`}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="btn"> Subscribe </button>
          </form>

          {errorMsg && <p className="error-message">Please enter a valid email address.</p>}

          <p className="newsletter-text">
            Get the email newsletter and unlock access to members-only content and updates
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
