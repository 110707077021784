// JSX
import React from 'react';
import success from "../assets/icon-success.svg";
import { useNavigate, useLocation } from "react-router-dom";


const Success = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state;

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="success-container">
      <img
        className="success-icon"
        src={success}
        alt="success-icon"
      />
      <h1 className="success-title">
        Thanks for subscribing!
      </h1>
      <p className="success-text">
        A confirmation email has been sent to
        <span className="success-email"> {email} </span>
        We appreciate your subscription. We'll keep you updated with our latest insights, narratives, and innovations in mental health through email.
      </p>
      <button
        onClick={handleClick}
        className="success-button"
      >
        Dismiss message
      </button>
    </div>
  );
};

export default Success;
